/*------------------------------------*\
    Page Template: Prodotti
    prodotti.php
    .page-template-prodotti
\*------------------------------------*/

// .page-template-prodotti


.page-template-prodotti{
    overflow-x: hidden;
    
    .decoration{
        position: absolute;
        z-index: -1;
        left: -100px;
    }

    .section1{
        display: flex;
    
        .left__column{
            width: 35%;
            padding-right: 20px;
        }
    
        .center__column{
            width: 35%;
            padding-top: 60px;
        }
    
        .right__column{
            width: 30%;
            margin-left: 80px;
            padding-top: 80px;

            @media #{$media-sm} {
                padding-top: 20px;
            }
        }
    
        .img{
            width: 90%;
            height: 100px;
            background-color: red;
            margin: 10px;
        }

        .image1{
            margin: 20px;
            width: 100%;
            height: auto;

            @media #{$media-sm} {
                margin: 0;
            }
        }

        .image2{
            margin-bottom: 40px;
            width: 100%;
            height: auto;
        }
    }

    .box {
        border-bottom: 1px solid var(--blue-logo);
        width: 100%;
        
        .box__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 40px 20px;
            width: 100%;
            cursor: pointer;

            .box__header__left{
                display: flex;
                align-items: center;

                h3{
                    font-size: 32px;
                    color: var(--blue-logo);
                    margin-left: 80px;
                }
            }

            .logo {
                width: 150px; /* Regola la dimensione del logo */
                max-height: 80px;

                @media #{$media-sm} {
                    width: 100px;
                }

                img{
                    width: 100%;
                    height: auto;
                }
            }

            .arrow {
                font-size: 24px;
                cursor: pointer;
                transition: transform 0.3s;
            }

            .rotate {
                transform: rotate(180deg); /* Ruota la freccia di 180 gradi quando è espanso */
            }
        }

        .box__content {
            display: none; /* Nascondi il contenuto iniziale */
            padding: 0 20px 20px 20px;

            .paragraph{
                display: none;
            }
        
            p{
                margin-left: 230px;
                font-family: montserrat;

                @media #{$media-sm} {
                    text-align: left;
                }
            }
        }   
    }

//responsive
    @media only screen and (max-width: 990px) {

        .section1{
            flex-direction: column;

            .left__column{
                width: 100%;
                padding-right: 0px;

                .paragrafo_page{
                    text-align: center;
                }
            }

            .center__column{
                width: 100%;
            }

            .right__column{
                width: 100%;
                margin-left: 0;
            }

        }

        .box{
            .box__header{
                padding: 0;
                padding: 20px 0;
                
                cursor: pointer;

                .box__header__left{

                    h3{
                        font-size: 20px;
                        font-family: oswald-light;
                        margin-left: 20px;
                    }
                        
                    svg{
                        width: 50%;
                    }   
                }
            }

            .box__content{
                padding: 0;
                
                p{
                    margin-left: 0px;
                }
            }
        }  
    }
}

