/*------------------------------------*\
    Form
   #form.form
\*------------------------------------*/

//Form richiesta informazioni
#gform_1{
    background-color: var(--form-bg);
    padding: 40px;

    .gfield{

        h1{
            font-family: oswald-light;
            font-size: 40px;
            color: var(--blue-logo);
            padding-bottom: 20px;
        }

        p{
            font-family: montserrat;
        }

        label{
            font-size: 18px;
            color: var(--blue-logo);
            font-family: montserrat;
        }

        .gfield_required{
            display: none;
        }

        input, textarea{
            background-color: #F4F4F4;
            border: 1px solid black;
            border-radius: 0px;
        }
    }

    button{
        font-family: oswald;
        font-size: 22px;
        color: white;
        background-color: var(--blue-logo);
        padding: 10px 20px;
        border-radius: 20px;
    }
}

//Form contatti
#gform_2{

    h1{
        font-family: oswald-light;
        font-size: 40px;
        color: var(--blue-logo);
        padding-bottom: 20px;
    }

    label{
        font-size: 18px;
        color: var(--blue-logo);
    }

    .gfield_required{
        display: none;
    }

    input, textarea{
        background-color: #F4F4F4;
        border: 1px solid black;
        border-radius: 0px;
    }

    button{
        font-family: oswald;
        font-size: 22;
        color: white;
        background-color: var(--blue-logo);
        padding: 10px 20px;
        border-radius: 20px;
    }
}

//Form preventivi
#gform_3{

    h1{
        font-family: oswald-light;
        font-size: 40px;
        color: var(--blue-logo);
        padding-bottom: 20px;
    }

    h2{
        padding-bottom: 0px;
        font-size: 28px;
        font-family: oswald-light;
        text-transform: none;
    }

    label{
        font-size: 18px;
        color: var(--blue-logo);
    }

    .gfield_required{
        display: none;
    }

    input, textarea, select{
        background-color: #F4F4F4;
        border: 1px solid black;
        border-radius: 0px;
    }

    option, input{
        font-family: montserrat;
    }

    .gform-field-label{
        font-family: montserrat;
    }

    button{
        font-family: oswald;
        font-size: 22;
        color: white;
        background-color: var(--blue-logo);
        padding: 10px 20px;
        border-radius: 20px;
    }

    .gfield_description{
        font-family: montserrat;
        font-style: italic;
        color: var(--blue-logo);
    }

    .gform_confirmation_message{
        font-family: montserrat;
    }
}