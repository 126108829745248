/*------------------------------------*\
    Page Template: Metodo
   metodo.php
    .page-template-metodo
\*------------------------------------*/

// .page-template-metodo


.page-template-metodo{  
    overflow-x: hidden;

    .decoration{
        position: absolute;
        z-index: -1;
        left: -100px;
    }

    .section1{
        display: flex;

        .left_column{
            width: 63%;
        }

        .right_column{
            width: 37%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .section2{
        .grafico{
            position: relative;
            height: 110%;
            width: 100%;
            display: flex;
            flex-direction: row;
            margin-top: 80px;

            @media #{$media-sm} {
                flex-direction: column;
                padding-bottom: 100px;
            }

            .grafico_line{
                position: absolute;
                left: 50%;
                z-index: -1;
                width: 2px;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;

                @media #{$media-sm} {
                    left: 40px;
                    height: 100%;
                }

                .line{
                    height: 100%;
                    width: 3px;
                    background-color: var(--blue-logo);
                
                    @media #{$media-sm} {
                        height: 100%;
                    }
                }

                .arrow{
                    position: absolute;
                    bottom: -10px;
                }
            }

            .grafico_box{
                position: relative;
                display: flex;
                margin-top: 40px;
                
                @media #{$media-sm} {
                    margin-left: 40px;
                }

                .grafico_dot{
                    position: absolute;
                    top: 16px;
                }

                .grafico_box_line{
                    height: 2px;
                    width: 25%;
                    background-color: var(--blue-logo);
                    margin: 32px 40px;

                    @media #{$media-sm} {
                        display: none;
                    }
                }

                .grafico_box_text{
                    width: 75%;

                    @media #{$media-sm} {
                        width: 100%;
                        padding-left: 40px;
                    }

                    .title{
                        font-size: 40px;
                        font-family: oswald-light;
                        color: var(--blue-logo);
                        text-transform: capitalize;

                        @media #{$media-sm} {
                            text-align: left;
                        }
                    }

                    .paragrafo{
                        font-size: 20px;
                        font-family: montserrat;
                        margin-top: 20px;
                        
                        @media #{$media-sm} {
                            text-align: left;
                        }
                    }
                }
            }

            .grafico_left{
                width: 50%;
                height: 100%;

                @media #{$media-sm} {
                    width: 100%;
                }

                .grafico_box{
                    flex-direction: row-reverse;

                    @media #{$media-sm} {
                        flex-direction: row;
                    }
                
                    .grafico_dot{
                        right: -18px;

                        @media #{$media-sm} {
                            left: -16px;
                        }
                    }

                    .grafico_box_text{
                        text-align: right;
                    }
                }
            }

            .grafico_right{
                margin-top: 120px;
                width: 50%;
                height: 100%;

                @media #{$media-sm} {
                    margin-top: 0px;
                    width: 100%;
                }

                .grafico_box{
                    flex-direction: row;
                
                    .grafico_dot{
                        left: -16px;
                    }

                    .grafico_box_text{
                        text-align: left;
                    }
                }
            }
        }

        #grafico_desktop{
            display: flex;
            
            @media #{$media-sm} {
               display: none;
            }
        }

        #grafico_mobile{
            display: none;
            
            @media #{$media-sm} {
               display: flex;
            }
        }

        .btn_box{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            @media #{$media-sm} {
                justify-content: flex-start;
            }

            .blue_btn{
                font-family: oswald;
                font-size: 25px;
                color: white;
                background-color: var(--blue-logo);
                padding: 10px 30px;
                border-radius: 30px;
                margin-top: 40px;
            }
        }
    }

    //Responsive
    @media only screen and (max-width: 990px) {   
    
        .section1{
            flex-direction: column;

            .left_column{
                width: 100%;
            }

            .right_column{
                width: 100%;
                padding-top: 40px;
            }
        }


    }
}