//common

.slick-arrow {
    position: absolute;
    bottom: $gutter;
    z-index: 2;
    border-radius: 20px;
    background-color: transparent;
    background-image: url('../images/icon_arrow.svg');
    background-repeat: no-repeat;
    background-size: 50%;
    border: 0;
    outline: none;
    width: 70px;
    height: 50px;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: $trans-default;
    color: transparent;

    &.pp2 {
        transform: rotate(180deg);
        left: - $gutter / 2;
        pointer-events: none;
        opacity: 0.2;
        filter: grayscale(1);

        @media #{$media-sx} {
            left: 0;
        }
    }

    &.nn2 {
        right: - $gutter / 2;
        padding: 0;

        @media #{$media-sx} {
            right: 0;
        }
    }

    &.pp2.enabled,
    &.nn2.enabled {
        pointer-events: all;
        opacity: 1;
        filter: none;
    }

    &.pp2.disabled,
    &.nn2.disabled {
        pointer-events: none;
        opacity: 0.2;
        filter: grayscale(1);
    }
} 


//slider realizzazioni

.slider_container{
    background-color: var(--slider-bg);
    display: flex;
    padding: 40px 0 40px 40px;

    @media #{$media-sm} {
        flex-direction: column;
        padding: 40px;
    }

    @media #{$media-sx} {
        padding: 20px;
    }

    
    .slider_text_box{
        width: 350px;
        height: 350px;
        min-width: 350px;
        min-height: 350px;
        background-color: var(--red-logo);
        padding: 20px;
        color: white;
        font-family: montserrat;
        font-size: 20px;

        @media #{$media-sm} {
            width: 100%;
            min-width: 0;
            width: fit-content;
            min-width: fit-content;
        }

        h3{
            font-family: oswald;
            font-size: 40px;
            text-transform: uppercase;
            padding-bottom: 20px;
        }
        
    }
    
    .slider_big_box{
        overflow: scroll;
    }

    .slider{
        display: flex;

        button{
            content: '->';
        }

        .slick-list{
            overflow: hidden;
            
            @media #{$media-sm} {
                overflow: scroll;
            }
        }


        .slider_box{
            width: 100%;
            height: 350px;
            min-width: 350px;
            min-height: 350px;
            background-color: white;
            position: relative;

            @media #{$media-sx} {
                width: 100%;
                height: 100%;
            }
            
            .text_box1, .text_box2{
                position: absolute;
                left: 20px;
                color: #F4F4F4;
                font-family: oswald;
                //box-shadow: ;
            }

            .text_box1{
                bottom: 40px;
                font-size: 25px;
            }

            .text_box2{
                bottom: 20px;
                font-size: 18px;
            }

            picture{
                width: 100%;
                height: 100%;
                overflow: hidden;

                img{
                    width: 100%;
                    height: 350px;
                    filter: brightness(50%);
                    object-fit: cover;
                    transition: .1s linear;
                }
    
                img:hover{
                    -webkit-transition: 0.1s linear;
                    filter: brightness(30%);
                    -webkit-transform: scale(1.15);
                    transform: scale(1.15);
                }
            }
        }
        // Elemento finale con link
        .final_item{
            background-image: url('../images/logo_brand_colori.svg');
            background-position: cover;
            filter: brightness(80%);

            .slider_box{
                display: flex;
                align-items: center;
                justify-content: center;

                a{
                    font-family: oswald;
                    font-size: 40px;
                    text-transform: uppercase;
                    padding-bottom: 20px;
                }
            }
        }
    }

}
 
 //slider immagini

 .single-cantiere, .single-cartongesso, .single-isolamento {
    .slider{
        .slick-track{
            margin-left: 0;
        }

        .slick-prev, .slick-next{
            position: absolute;
            top: 340px;

            @media #{$media-u} {
               top: 310px;
            }    

            @media #{$media-m} {
                top: 250px;
             }    
        }

        .slick-prev{
            right: 140px;
            transform: rotate(180deg);
        }

        .slick-next{
            right: 40px;
        }

        .item{
            margin-right: 40px;
            aspect-ratio : 1 / 1;

            .slider_box{
                width: 100%;
                height: 100%;
                
                figure{
                    height: 100%;

                    a{
                        width: 100%;
                        height: 100%;

                        picture{
                            height: 100%;
                            overflow: hidden;
                                                
                            img{
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                transition: 0.1s linear;
                            }

                            img:hover{
                                -webkit-transition: 0.1s linear;
                                filter: brightness(30%);
                                -webkit-transform: scale(1.15);
                                transform: scale(1.15);
                            }
                        }

                    }
                }

            }
        }
    }

    //gallery

    .gallery{
        background-color: #F4F4F4;
        padding: 60px 0px 60px 80px;
        
        @media #{$media-m} {
            padding: 40px 0px 40px 40px;
        }

        @media #{$media-sx} {
            padding: 40px 0px 40px 20px;
        }

        h3{
            font-family: oswald;
            font-size: 25px;
            text-transform: uppercase;
            padding-bottom: 40px;
        }


        .vediGallery {
            display: flex;
            align-items: center;
            align-self: center;
            gap: 10px;
            text-decoration: underline;
            font-size: 1.6rem;
            margin: $gutter / 2 0 0 0;
            transition: $trans-default;

            @media #{$media-bx} {
                font-size: 1.4rem;
            }

            @media #{$media-b} {
                margin: $gutter / 2 0;
            }

            svg {
                @media #{$media-b} {
                    width: 25px;
                }

                path {
                    transition: $trans-default;
                }
            }

            &:hover {
                color: var(--c-accent);
                transform: scale(1.05);

                svg {
                    path {
                        fill: var(--c-accent);
                    }
                }
            }
            
        }



        .gallery--image {
            margin-bottom: $gutter * 2;

            .slider {
                display: flex;
                overflow-x: scroll;
                overflow-y: hidden;

                figure {
                    position: relative;
                    //overflow: hidden;
                    width: 800px;
                    height: auto;
                    margin-right: 40px;

                    &::before {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        background-color: var(--c-accent);
                        opacity: 0;
                        z-index: 2;
                        top: 0;
                        left: 0;
                        transition: $trans-default;
                        pointer-events: none;
                    }

                    &:hover {

                        &::before {
                            opacity: 0.2;
                        }
                    }

                    a {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                    }

                    picture {
                        width: 100%;
                        transition: $trans-default;

                        img{
                            max-width: none;
                            object-fit: cover;
                        }
                    }
                }
            }
        }
    }
}