/*------------------------------------*\
    Header
   #header.header
\*------------------------------------*/
//rendo l'header unico per L'homepage
.page-template-homepage header{
    position: relative;
    
    .header_menu_mobile #hamburger_menu rect {
        fill: white;
    }

    svg{
        path, rect{
            fill: white;
        }
    }
    
    .header_content{
        color: white;

        .header_menu{
            
            #menu-header_menu li a:active{
                color: white;
            }

            //imposta freccia bianca sull'header (solo in HP) come sfondo nell'after
            .menu-item-has-children::after {
                background-image: url('../images/icon_arrow_down_white.svg'); 
            }
        }
    
        .header__icons{
    
            //imposto il bordo delle icone bianco solo per la HP
            .header_icon{
                border: 1px solid white;
            }
        }

        .header_menu_mobile {

            //metto l'svg e il bordo bianchi solo per la HP
            #hamburger_menu rect {
                fill: white;
            }

            label{
                border: 2px solid white;
            }
        }
        
    }    
    
    .line{
        border: 0.5px solid white;
    }
}


.header{
    z-index: 200;

    .header__logo{
        width: fit-content;

        a{
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    .header_content{
        position: relative;
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 40px 10px 40px;
        color: var(--blue-logo);

        @media #{$media-sm} {
            padding: 20px;
        }

        /* !! MENU DESKTOP !! */

        .header_menu{
            display: flex;
            align-items: center;

            @media #{$media-m} {
                display: none;
            }

            #menu-header_menu{
                display: flex;
                justify-content: space-around;
                align-items: center;
            }

            #menu-header_menu li a{
                text-transform: uppercase;
                margin-left: 20px;
                font-size: 18px;
                font-family: oswald-light;
                font-weight: lighter;
            }

            #menu-header_menu li a:active{
                color: red;
            }

            .sub-menu{
                display: none;
                top: 60px;
                left: 20px;
                width: 96%;
                height: fit-content;
                min-height: 320px;
                margin: 0 2%;
                padding: 68px 40px 40px 40px;
                position: absolute;
                background: linear-gradient(to bottom, transparent 28px, #D5D5D5 20px);
                flex-direction: column;
                align-items: center;
            }
            
            // Imposto i link neri
            .sub-menu a{
                color: black;
            }

            // Imposto i link custom rossi (sono i titoletti dei menù)
            .sub-menu .menu-item-type-custom a{
                color: red;
                cursor: default;
            }

            // Imposto le voci legati alle pagine cartongesso e isolamenti di nero.
            .sub-menu .menu-item-object-cartongesso a,
            .sub-menu .menu-item-object-isolamento a{
                color: black;
                cursor: pointer;
                
                &:hover{
                    color: red;
                }
            }

            a:hover{
                color: var(--red-logo);
            }

            //All'hover mostra il sub-menu
            a:hover + .sub-menu,
            .sub-menu:hover {
            display: flex;
            }
            
            .sub-menu:hover{
                .sub-menu{
                    display: flex;
                }
            } 

            // Imposta freccia blue sull'header come sfondo nell'after
            .menu-item-has-children::after {
                content: "";
                background-image: url('../images/icon_arrow_down_blue.svg'); 
                width: 20px; 
                height: 10px;
                display: inline-block;
                background-size: cover;
                background-position: center;
            }

            // Tolgo la freccia nei sub menu
            .sub-menu .menu-item-has-children::after {
                content: none;
            }

            // Personalizzo questi id per gestire la sezione dei link legati ai cartongessi (versione desktop)
        
            // Id della voce cartongessi
            #menu-item-308 .sub-menu{
                flex-direction: row;
                justify-content: center;
                align-items: flex-start;
                
                // Id delle voci dei titoletti rossi
                #menu-item-480 .sub-menu,
                #menu-item-492 .sub-menu {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    padding: 0px;
                    width: fit-content;
                    min-height: fit-content;
                    top: 0px;
                    margin: 0px;
                }
            }

            
        }

        .header__icons{
            display: flex;

            .header_icon{
                margin-left: 20px;
                border: 1px solid red;
                width: 40px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        /* !! MENU MOBILE !! */

        .header_menu_mobile{
            display: none;

            @media #{$media-m} {
                display: block;
            }

            // Logo menu mobile
            .header__logo{
                padding-top: 20px;

                //imposto il logo bianco
                rect, path{
                    fill: white;
                }
            }

            label{
                border: 2px solid var(--red-logo);
                width: 40px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            // Colore svg 
            #hamburger_menu rect {
                fill: var(--blue-logo); 
            }

            #menu-toggle {
                display: none;
            }

            #menu-icon, #menu-close {
                cursor: pointer;
                z-index: 1;
            }
    
            #menu-close {
                display: none;
                z-index: 101;
                position: absolute;
                top: 30px;
                right: 20px;
                border: 2px solid white;
            }
    
            // Menu laterale mobile
            #menu {
                background-color: var(--blue-logo);
                color: white;
                position: absolute;
                top: 0;
                right: -100%;
                height: fit-content;
                min-height: 100vh;
                width: 100%;
                transition: right 0.3s;
                display: flex;
                flex-direction: column;
                z-index: 100;
                padding: 0 20px;
                overflow-x: hidden;

                nav{
                    border-top: 1px solid white;
                    margin-top: 20px;
                }
                
                ul {
                    list-style-type: none;
                
                    li {
                        margin-top: 20px;

                        &::after{
                            content: "";
                            width: 100%;
                            display: block;
                            height: 1px;
                            background-color: white;
                            margin-top: 20px;
                        }

                        a {
                            color: #fff;
                            text-decoration: none;
                            font-size: 20px;
                            //text-transform: uppercase;
                            font-family: oswald-light;
                            padding-left: 20px;
                        }
                    }    
                }

                .header__icons{
        
                    .header_icon{
                        border: 1px solid white;
                       
                        svg{
                            path{
                                fill: white;
                            }
                        }
                    }
                }
            }

            //quando apro il sottomenù tolgo il margine 
            .menu-item-has-children.open::after {
                margin-top: 0px!important;
                content: none!important;
            }


            #menu-toggle:checked + #menu-icon + #menu-close + #menu {
                right: 0;
            }
    
            #menu-toggle:checked + #menu-icon {
                display: none;
            }
    
            #menu-toggle:checked + #menu-icon + #menu-close {
                display: flex;
            }

            // METTO la freccia come bg agli elementi che hanno figli
            .menu-item-has-children a::after {
                content: "";
                background-image: url('../images/icon_arrow_down_white.svg'); 
                width: 20px; 
                height: 10px;
                display: inline-block;
                background-size: cover;
                background-position: center;
                margin-left: 20px;
                transition: transform 0.3s;
            }

            .sub-menu{
                display: none;
                background-color: white;
                margin: 20px -20px 0 -20px;
                padding-top: 1px;
                border-top: 1px solid black;
                
                li {
                    margin-top: 20px;

                    &::after{
                        background-color: black!important;
                    }
                }

                // METTO la freccia come bg agli elementi nel sub-menu che hanno figli
                .menu-item-has-children {
                    a{
                        margin-left: 20px;
                        
                        &::after {
                            content: "";
                            background-image: url('../images/icon_arrow_down_blue.svg'); 
                            width: 20px; 
                            height: 10px;
                            display: inline-block;
                            background-size: cover;
                            background-position: center;
                        }
                    }

                    .menu-item-object-cartongesso a,
                    .menu-item-object-isolamento a{
                        margin-left: 60px!important;
                    }
                    
                    //TOLGO la freccia come bg agli elementi figli 
                    .menu-item-object-page a::after,
                    .menu-item-object-cartongesso a::after,
                    .menu-item-object-isolamento a::after {
                        content: none;
                        background-image: none; 
                    }

                }
                

                //imposta il testo dei link dei sub-menu di secondo livello e delle news
                .menu-item-object-page, .menu-item-type-taxonomy, .menu-item-object-cartongesso, .menu-item-object-isolamento{
                    a{
                        color: black!important;
                        font-weight: lighter;
                        margin-left: 40px!important;
                    }
                }

                //imposta di blu il sub-menu di primo livello
                li a{
                    color: var(--blue-logo)!important;
                    font-size: 20px;
                }
            }

            .menu-item-has-children.open > .sub-menu { 
                display: block; 
            }

            .menu-item-has-children.open > a::after {
                transform: rotate(180deg);
            }

            //display_none sui titoli degli elenchi nel menù mobile
            .menu-item-444, .menu-item-443, .menu-item-479{
                display: none;
            }

            .header__icons{
                display: flex;
                flex-direction: row;
                justify-content: center;

                a{
                    margin: 40px;
                }
            }            
        }
    }    

    .line{
        margin: 0 40px;
        border: 0.5px solid var(--blue-logo);

        @media #{$media-sm} {
            margin: 0 20px;
        }
    }

}   


