/*------------------------------------*\
    Footer
   #footer.footer
\*------------------------------------*/

.upper__footer{
    margin-top: 120px;
    background-color: var(--blue-logo);
    color: white;
    padding: 40px 60px;
    display: flex;
    justify-content: space-between;
    font-family: montserrat;
    font-size: 14px;
    z-index: 1;

    a:hover{
        color: white;
    }

    h3{
        text-transform: uppercase;
        font-family: oswald;
        font-size: 16px;
    }

    .footer__left{
        width: 70%;

        .footer__logo{
            margin-bottom: 30px;
        }

        .footer__contatti{
            display: flex;
            justify-content: space-around;

            .footer__contatto{
                margin-right: 40px;
                
                .footer__contatto_up{
                    display: flex;
                    margin-bottom: 20px;

                    @media #{$media-s} {
                        margin-bottom: 10px;
                    }

                    .icon{
                        padding-right: 20px;
                    }                       
                }

                .footer__contatto_down{
                    padding-left: 40px;
                }
            }
            
            //ho inserito un id unico perchè l'svg della email si comportava in modo anomalo
            #footer__contatto_scrivici{
                .footer__contatto_up{
                    display: flex;
                    align-items: baseline;   
                    
                    .icon{
                        padding-right: 13px;
                    }
                }
            }

        }
        
        
    }

    .footer__right{
        margin: 60px 0;
        display: flex;

        .socials__box{
            display: flex;
            flex-direction: column;
            align-items: center;

            .socials{
                display: flex;
                margin-top: 30px;
                
                .social{
                    margin-right: 30px;
                    border: 1px solid white;
                    border-radius: 50px;
                    width: 50px;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .social:last-of-type{
                    margin-right: 0;
                }
            }    
            

        }

        .footer__fastlink__menu{
            margin-left: 80px;

            #menu-footer_fastlink_menu{
                list-style: circle;
            }
        }
    }
}

.lower__footer{
    background-color: var(--blue-dark);
    color: white;
    display: flex;
    justify-content: space-between;
    padding: 20px 60px;
    font-family: Arial, Helvetica, sans-serif;
    
    .items{
        display: flex;

        .item{
            margin: 0 10px;
            text-transform: uppercase;

            a:hover{
                color: white;
                text-decoration: underline;
            }
        }
    }
}


@media only screen and (max-width: 1368px) {  
    .upper__footer{
        .footer__left{
            .footer__contatti{
                .footer__contatto, #footer__contatto_scrivici{
                    .footer__contatto_down{
                        padding-left: 0px;
                    }
                }
            }
        }
    }    
}


@media only screen and (max-width: 990px) {    

    .upper__footer{
        flex-direction: column;
        padding: 40px;
   
        .footer__left{
                width: 100%;
                .footer__logo{
                    margin-bottom: 0px;
                }

                .footer__contatti{
                    .footer__contatto, #footer__contatto_scrivici{
                        .footer__contatto_down{
                            padding-left: 41px;
                        }
                    }
                }

            .footer__left__icons{
                flex-direction: column;
            }

        }
        
        .footer__right{
            display: flex;
            align-items: baseline;
            margin: 40px 0 0 0;
            justify-content: space-around;

            .socials__box{
                display: block;
            }
        }
    }

    .lower__footer{
        flex-direction: column;
        padding: 20px;
        text-align: center;

        .items{
            align-self: center;
            margin: 20px 0;
        }
    }
}

@media only screen and (max-width: 750px) {  
    .upper__footer{
        .footer__left{

            .footer__logo{
                display: flex;
                justify-content: center;
            }

            .footer__contatti{
                flex-direction: column;
    
                .footer__contatto{
                    margin-top: 40px;

                    .footer__contatto_up h3{
                        margin-bottom: 0;
                    }
                }
            }
        }
    
        .footer__right{
            flex-direction: column;
            align-items: flex-start;
            margin-left: 40px;

            .footer__fastlink__menu{
                margin: 40px 0px;
            }
        }
    }
}