@media screen and (prefers-reduced-motion: no-preference) {

	html,
	body {
		scroll-behavior: smooth;
	}

}

html {
	font-size: 62.5%;

    &.main__nav-opened { 
        overflow: hidden;
    }
}
// fix margin wpadminbar
html[lang] { margin-top: 0 !important; }

	body {

		color: var(--c-fg);
		background-color: var(--c-bg);
        font-family: var(--f-text);
		font-weight: var(--f-400);
		font-size: 1.6rem;
		//line-height: 2;
		line-height: 1.6;
        -webkit-text-size-adjust: 100%;

		&.main__nav-opened {
			height: 100%;

			/* Hide scrollbar for Chrome, Safari and Opera */
			&::-webkit-scrollbar {
				display: none;
			}

			-ms-overflow-style: none;  /* IE and Edge */
			scrollbar-width: none;  /* Firefox */

			 // position: fixed;

		}// &.main__nav-opened

	}
	@-moz-document url-prefix() {
    	html.main__nav-opened,body.main__nav-opened{ overflow: hidden; }
	}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			font-family: $f-family-title;
		}

		p {
            margin-bottom: $gutter / 2;
        }

		strong, b {
			font-weight: $f-weight-700;
		}

		a {
			text-decoration: none;
			color: currentColor;
            transition: $trans-default;

            &:hover {
                color: var(--c-accent);
            }
		}










/*------------------------------------*\
	General style here
\*------------------------------------*/
//s.f. global

.container_page{
    padding: 40px 80px 0 80px;
    overflow-x: hidden;

    @media #{$media-m} {
        padding: 40px;
    }

    @media #{$media-sx} {
        padding: 40px 20px;
    }
}

h1{
    font-size: 16px;
    margin: 0;
    padding-bottom: 40px;
    font-family: montserrat;

    @media #{$media-sm} {
        font-size: 14px;
    }
}

h2{
    font-size: 50px;
    color: var(--blue-logo);
    text-transform: uppercase;
    margin: 0;
    padding-bottom: 40px;
    font-family: oswald-light;

    @media #{$media-sm} {
        font-size: 35px;
    }
}

.paragrafo{
    font-size: 18px;
    font-family: montserrat;   
    
    @media #{$media-sm} {
        font-size: 18px;
        text-align: center;
    }
}

.custom_link{
    color: var(--blue-logo);
    text-transform: uppercase;
    text-decoration: underline;
    font-family: montserrat;
    font-size: 20px;
    width: fit-content;
    display: flex;
    align-items: center;

    &:hover{
        color: var(--red-logo);
    }

    &::after{
        content: "";
        background-image: url('../images/icon_link_arrow.svg');
        width: 20px; 
        height: 20px;
        display: inline-block;
        background-size: cover;
        background-position: center;
        margin-left: 10px; 
    }
}

.decoration{
    position: absolute;
}

    //

::selection {
	background-color: var(--c-accent);
	color: white;
}

:focus {
	outline: .1rem solid var(--c-accent);
	outline-offset: .2rem;
}

.none { display: none; }

.hidden { visibility: hidden; }

.uppercase { text-transform: uppercase; }

.anchor {
    display: block;
    position: relative;
    top: calc(var(--h-header) * -1);
    visibility: hidden;
}

// Screen reader only
.sr-only {
	border: 0 !important;
	clip: rect(1px, 1px, 1px, 1px) !important;
	-webkit-clip-path: inset(50%) !important;
	clip-path: inset(50%) !important;
	height: 1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
	white-space: nowrap !important;
}

.image-auto {
	width: auto;
	height: auto;
}

.image-fit {
	width: 100%;
	height: auto;
    max-width: none;
    object-fit: cover;
}

.title {
    font-family: $f-family-title;
}

.text {
    font-family: $f-family-text;
}

figure, picture {
    display: flex;
}

.noheight {
    height: 0;
    line-height: 0;
    visibility: hidden;
    display: none;
}



/*------------------------------------*\
    Text
    .text
\*------------------------------------*/

.text {

    a {
        color: currentColor;
    }

    ul,
    ol {
        margin: 2rem;
    }

    ol {
        list-style-type: decimal;
    }

    li {
        list-style-type: disc;
    }

    p+p {
        margin-top: $gutter * .5;
    }

    strong {
        font-weight: $f-weight-700;
    }

    em {
        font-style: italic;
    }

}// .text










/*------------------------------------*\
    Back link
    .back
\*------------------------------------*/

.back {

    padding: $gutter;
    text-align: center;

    .link {

        display: inline-flex;
        align-items: center;
        position: relative;
        height: 4.5rem;
        padding-left: $gutter*.5;
        padding-right: $gutter*2;

        font-family: $f-family-title;
        font-weight: $f-weight-700;
        font-size: 1.8rem;
		line-height: 1;
        text-transform: uppercase;
        color: var(--c-white);
        background-color: var(--c-corporate-1);

        @media #{$media-sx} {
            font-size: 1.6rem;
            padding-right: $gutter * 1.5;
        }

        &::after {

            display: inline-flex;
            content: '';
            width: 0;
            height: 0;
            position: absolute;
            top: 0;
            right: 0;
            border-left: 4.5rem solid transparent;
            border-right: initial;
            border-top: initial;
            border-bottom: 4.5rem solid var(--c-white);

        }

        &:hover,
        &:focus-within {
            color: var(--c-corporate-1);
            background-color: var(--c-corporate-5);
        }

    }// .link

}// .back


//custom css dal sito

#menu-item-193 .sub-menu{
	flex-direction: row;
    justify-content: center;
 	align-items: flex-start;
}

#menu-item-198 .sub-menu,
#menu-item-199 .sub-menu,
#menu-item-200 .sub-menu,
#menu-item-201 .sub-menu,
#menu-item-202 .sub-menu {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0px;
    width: fit-content;
    min-height: fit-content;
    top: 0px;
    margin: 0px;
}

@media screen and (max-width: 1080px) {
    .menu-item-197,
    .menu-item-196 {
        display: none;
    }
}

.section2_mobile{
	display: none;
}

@media screen and (max-width: 1080px) {
    .section2_mobile{
	    display: block;
		margin-top: 80px;
    }
}

@media screen and (max-width: 1080px) {
    .section2_desktop{
	    display: none;
    }
}

.header .header_content .header_menu .sub-menu{
	margin: 0 1%;
}