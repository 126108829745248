/*------------------------------------*\
    Page Template: Single cantiere
    single-cantiere.php
    .single-cantiere
\*------------------------------------*/

.single-cantiere {
    overflow-x: hidden;

    .container_page{

        h2{
            padding-bottom: 0px;
        }

        h3{
            font-size: 25px;
            color: var(--blue-logo);
            text-transform: uppercase;
            margin: 0;
            padding-bottom: 20px;
            font-family: oswald-light;
        }
    
        .decoration{
            top: -600px;
            left: -600px;
            z-index: -1;
            opacity: 0.31;
        }
    
        .section1{
            display: flex;
    
            .column1{
                width: 50%;
                margin-right: 40px;
    
                @media #{$media-sm} {
                    width: 100%;
                    margin-right: 0px;
                    margin-bottom: 40px;
                }
            }
    
            .column2{
                width: 50%;
                margin: 40px 40px 80px 40px;
    
                @media #{$media-sm} {
                    display: none;
                }
    
                picture{
                    width: 100%;
                    height: 600px;
                    position: relative;
                    overflow: hidden;
                }
    
                img{
                    object-fit: cover;
                    width: 100%;
                    height: auto;
                }
            }
        }
    }  
    
    

    .link_box{
        padding-top: 40px;
        display: flex;
        justify-content: center;
        
        .custom_link::after{
            content: none;
        }

        .custom_link::before{
            content: "";
            background-image: url('../images/icon_link_arrow.svg');
            width: 20px; 
            height: 20px;
            display: inline-block;
            background-size: cover;
            background-position: center;
            margin-right: 10px; 
            transform: rotate(180deg);
        }
    }

   
}
