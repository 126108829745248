/*------------------------------------*\
    Page Template: Homepage
    homepage.php
    .page-template-homepage
\*------------------------------------*/

// .page-template-homepage

.page-template-homepage{
    overflow-x: hidden;

    .house_bg{
        z-index: -1;
    }

    section{
        width: 100%!important;
    }

    .head_bg{
        position: absolute;
        top: -100px;
        //top: $header_height;
        z-index: -1;
        width: 100%;
        height: 100vh;

        img{
            max-width: none;
            width: 100%;
            height: 100%;
            object-fit: cover;
            filter: brightness(80%);
        }
    }

    //blocco iniziale
    .head_block{
        width: 100%;
        height: calc(100vh - 100px);
        position: relative;
        overflow: hidden;

        .v-argento{
            right: -200px;
            svg{
                path{
                    fill: white;
                    opacity: 0.3;
                }
            }
            
            @media #{$media-sm} {
                display: none;
            }
        }

        .text_box{
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            padding: 0 25% 10vh 25%;
            top: 150px;

            .inner_text_box{
                display: flex;
                align-items: center;
                justify-content: center;
            }

            @media #{$media-sm} {
                padding: 0 20px 10vh 20px;
            }

            .decoration1{
                filter: brightness(0.5) contrast(1) opacity(40%);
                position: absolute;      
                z-index: -1;
            }
            
            h1{
                text-transform: uppercase;
                color: white;
                font-size: 50px;
                text-align: center;
                font-family: oswald-light;

                text-shadow: 1px 1px 2px black;

                @media #{$media-sm} {
                    font-size: 43px;
                }

                @media #{$media-s} {
                    font-size: 40px;
                    padding-bottom: 20px;
                }

                @media #{$media-sx} {
                    font-size: 35px;
                }
            }
    
            p{
                font-size: 20px;
                color: white;
                text-align: center;
                font-family: montserrat;

                text-shadow: 1px 1px 2px black;

                @media #{$media-sm} {
                    font-size: 18px;
                    text-align: center;
                }
            }
        }

        
    }  


    //Tutte e tre le sezioni
    .section{
        display: flex;
        align-items: center;

        .box-image{
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .section__content{

            .title{
                font-size: 40px;
                font-family: oswald-light;
                color: var(--blue-logo);
                margin-bottom: 40px;

                @media #{$media-sm} {
                    font-size: 35px;
                }

            }

            .paragraph{
                font-size: 16px;
                font-family: montserrat;
            }

            a{
                display: flex;
                align-content: center;
                margin-top: 20px;
    
                @media #{$media-sm} {
                    font-size: 18px;
                }
    
                span{
                    margin-left: 20px;
                }
            }
        }        
    }

    //solo sezione 1 e 2
    .section1, .section2{

        .box-image{
            width: 600px;

            .imagesection1, .imagesection2{
                border-radius: 5000px;
                width: 200px;
                height: 200px;
                min-width: 200px;
                min-height: 200px;
                position: relative;
                bottom: 5px;
                object-fit: cover;
            }
        }
    }

    //solo sezione 1
    .section1{
        margin-top: 80px;

        .box-image{
            margin-left: 40px;

            @media #{$media-m} {
               margin-left: 0px;
            }
        }

        .section__content{
            padding-left: 80px;
            padding-right: 120px;
        }
    }

    //solo sezione 2
    .section2{
        margin: 120px 0;
    
        .box-image{
            margin-right: 40px;

            @media #{$media-m} {
                margin-right: 0px;
            }
        }

        .section__content{
            padding-left: 120px;
            padding-right: 80px;
            text-align: right;
            display: flex;
            flex-direction: column;
            align-items: end;
        }

        a{
            justify-content: end;
        }
    }

    //solo sezione 3
    .section3 {
        margin: 120px 0;

        .box-image{
            width: 600px;
            position: relative;

            #vblue{
                right: 82px;
                z-index: -1;

                @media #{$media-m} {
                    right: 172px;
                }
            }

            #vwhite{
                left: 24px;

                @media #{$media-m} {
                    left: 127px;
                }

                path{
                    fill: white;
                    filter: blur(2px);
                    opacity: 0.8;
                }
            }
    
            .imagesection3{
                width: 400px;
                height: auto;
                min-width: 400px;
            }
        }

        .section__content{
            padding-left: 80px;
        }
    }

    .text_box1, .text_box2{
        z-index: 2;
    }

    //sezione notizie
    .news_section{
        font-family: montserrat;
        margin-bottom: 120px;

        .title{
            font-family: oswald-light;
            font-size: 32px;
            color: var(--blue-logo);
            padding-bottom: 10px;
        }

        .news_bar{
            display: flex;
            flex-direction: row;
            overflow-x: scroll;

            .news_block{
                margin: 10px;
                padding: 20px 10px;
                border-top: 1px solid black;
                min-width: 380px;

                @media #{$media-sx} {
                    min-width: 100%;
                }

                a{
                    padding-top: 80px;
                }
            }
        }

        a{
            font-size: 16px;
            display: flex;

            span{
                margin-left: 20px;
            }
        }

        .news_section_footer{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            border-top: 1px solid black;
            border-bottom: 1px solid black;
            padding: 20px;
        }
    }


//Sezione Responsive
    @media only screen and (max-width: 1080px) {           
        .section{
            display: flex;
            flex-direction: column;

            .box-image{
            display: flex;
            align-items: center;
            justify-content: center;
            }

            .section__content{
                margin: 60px 0 0 0;
                padding: 0; 
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
        
                a{
                    font-family: montserrat;
                    font-size: 20px;
                    display: flex;
                    align-items: center;
                    color: var(--blue-logo);
                    text-transform: uppercase;
                    text-decoration: underline;
                    padding-top: 20px;
                
                    span{
                        margin-left: 20px;
                    }
                }
            }
        }

        .section2{
            flex-direction: column-reverse;
        }
    }
}



