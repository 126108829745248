/* Magnific Popup CSS */
.mfp-bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1042;
    overflow: hidden;
    position: fixed;
    background: var(--c-bg);
    opacity: 1;
}

.mfp-wrap {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1043;
    position: fixed;
    outline: none !important;
    -webkit-backface-visibility: hidden;
}

.mfp-container {
    background-color: #0046AA6E;
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0 8px;
    box-sizing: border-box;
}

.mfp-container:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
    display: none;
}

.mfp-content {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0 auto;
    text-align: left;
    z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
    width: 100%;
    cursor: auto;
}

.mfp-ajax-cur {
    cursor: progress;
}

button.mfp-close {
    cursor: pointer;
    cursor: -webkit-zoom-out;
    cursor: -moz-zoom-out;
    cursor: zoom-out;
}

.mfp-zoom {
    cursor: pointer;
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
    cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
    cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.mfp-loading.mfp-figure {
    display: none;
}

.mfp-hide {
    display: none !important;
}

.mfp-preloader {
    color: #CCC;
    position: absolute;
    top: 50%;
    width: auto;
    text-align: center;
    margin-top: -0.8em;
    left: 8px;
    right: 8px;
    z-index: 1044;
}

.mfp-preloader a {
    color: #CCC;
}

.mfp-preloader a:hover {
    color: #FFF;
}

.mfp-s-ready .mfp-preloader {
    display: none;
}

.mfp-s-error .mfp-content {
    display: none;
}

button.mfp-close {
    overflow: visible;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
    display: block;
    outline: none;
    padding: 0;
    z-index: 1046;
    box-shadow: none;
    touch-action: manipulation;
}

button::-moz-focus-inner {
    padding: 0;
    border: 0;
}

.mfp-close {
    width: 44px;
    height: 44px;
    line-height: 44px;
    position: fixed;
    text-decoration: none;
    text-align: center;
    opacity: 1;
    padding: 0 0 18px 10px;
    font-style: normal;
    font-size: 50px;
    font-family: $f-family-title;
    top: 2rem;
    right: 2rem;
    color: transparent;
    cursor: pointer!important;

    &::before {
        content: '';
        position: absolute;
        color: var(--c-black);
        width: 50px;
        height: 50px;
        right: 0;
        top: 0;
        text-align: center;
        transform: scaleX(1.5);
    }
}

.mfp-close:hover,
.mfp-close:focus {
    opacity: 1;
}

.mfp-close:active {
    top: 1px;
}

.mfp-close-btn-in .mfp-close {
    color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
    color: #FFF;
    right: 4rem;
    text-align: right;
    width: 100%;
}

.mfp-counter {
    position: absolute;
    top: 0;
    right: 0;
    color: var(--c-corporate-1);
    font-size: 12px;
    line-height: 18px;
    white-space: nowrap;
    display: none;
}

.mfp-arrow {
    position: absolute;
    bottom: - $gutter * 1.5;
    z-index: 2;
    color: transparent;
    border-radius: 20px;
    background-color: var(--c-corporate-5) !important;
    background-image: url('../images/icon_arrow.svg') !important;
    background-repeat: no-repeat !important;
    background-size: 50% !important;
    border: 0;
    outline: none;
    width: 51px;
    height: 30px;
    background-position: center !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    transition: $trans-default;
    
    @media #{$media-m} {
        display: none !important;
    }
}

.mfp-arrow:active {
    margin-top: -54px;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
    opacity: 1;
}

.mfp-arrow:before,
.mfp-arrow:after {
    display: none;
}

.mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px;
}

.mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7;
}

.mfp-arrow-left {
    transform: rotate(180deg);
    left: $gutter;
}

.mfp-arrow-left:after {
    border-right: 17px solid #FFF;
    margin-left: 31px;
}

.mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F;
}

.mfp-arrow-right {
    right: $gutter;
}

.mfp-arrow-right:after {
    border-left: 17px solid #FFF;
    margin-left: 39px;
}

.mfp-arrow-right:before {
    border-left: 27px solid #3F3F3F;
}

.mfp-iframe-holder {
    padding-top: 40px;
    padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
    top: -40px;
}

.mfp-iframe-scaler {
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000;
}

/* Main image in popup */
img.mfp-img {
    width: auto;
    max-width: 100%;
    height: auto;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 40px 0 40px;
    margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
    line-height: 0;
}

.mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: none;
    background: #444;
}

.mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px;
}

.mfp-figure figure {
    margin: 0;
}

.mfp-bottom-bar {
    margin-top: -36px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    cursor: auto;
}

.mfp-title {
    text-align: center;
    line-height: 18px;
    color: var(--c-corporate-1);
    word-wrap: break-word;
    padding: $gutter / 2 0;
}

.mfp-image-holder .mfp-content {
    max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
    cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape),
screen and (max-height: 300px) {

    /**
         * Remove all paddings around the image on small screen
         */
    .mfp-img-mobile .mfp-image-holder {
        padding-left: 0;
        padding-right: 0;
    }

    .mfp-img-mobile img.mfp-img {
        padding: 0;
    }

    .mfp-img-mobile .mfp-figure:after {
        top: 0;
        bottom: 0;
    }

    .mfp-img-mobile .mfp-figure small {
        display: inline;
        margin-left: 5px;
    }

    .mfp-img-mobile .mfp-bottom-bar {
        background: rgba(0, 0, 0, 0.6);
        bottom: 0;
        margin: 0;
        top: auto;
        padding: 3px 5px;
        position: fixed;
        box-sizing: border-box;
    }

    .mfp-img-mobile .mfp-bottom-bar:empty {
        padding: 0;
    }

    .mfp-img-mobile .mfp-counter {
        right: 5px;
        top: 3px;
    }

    .mfp-img-mobile .mfp-close {
        position: fixed;
        top: 2rem;
        right: 2rem;
        color: transparent;

        &::before {
            content: 'X';
            position: absolute;
            color: var(--c-black);
            width: 50px;
            height: 50px;
            right: 0;
            top: 0;
        }
    }
}

@media all and (max-width: 900px) {
    .mfp-arrow {
        -webkit-transform: scale(0.75);
        transform: scale(0.75);
    }

    .mfp-arrow-left {
        -webkit-transform-origin: 0;
        transform-origin: 0;
    }

    .mfp-arrow-right {
        -webkit-transform-origin: 100%;
        transform-origin: 100%;
    }

    .mfp-container {
        padding-left: 6px;
        padding-right: 6px;
    }
}

.calcolaRataPopup, .popupGenerico {
    backdrop-filter: blur(10px);
    background: transparent;

    .mfp-close {
        &::before {
            display: none;
        }
    }

    .white-popup {
        position: relative;
        background: var(--c-corporate-3);
        width: auto;
        max-width: 800px;
        margin: $gutter auto;
        display: flex;
        flex-direction: column;

        .top {
            background-color: var(--c-corporate-5);
            color: var(--c-white);
            font-size: 3rem;
            font-weight: 700;
            padding: $gutter / 6 $gutter / 2;

            @media #{$media-sx} {
                font-size: 2.4rem;
            }
        }

        .content {
            display: flex;
            flex-direction: column;
            gap: 15px;
            padding: $gutter * 1.2 $gutter;

            @media #{$media-sx} {
                padding: $gutter * 1.2 $gutter / 2;
            }

            .row {
                display: flex;
                flex-wrap: wrap;
                gap: 20px;

                .title {
                    font-size: 2rem;
                    width: 25%;

                    @media #{$media-s} {
                        width: 39%;
                        flex: 1 0 auto;
                    }

                    @media #{$media-sx} {
                        font-size: 1.8rem;
                    }
                }

                .text {
                    font-size: 2rem;
                    font-family: $f-family-menu;
                    font-weight: 900;

                    @media #{$media-s} {
                        flex: 1 0 auto;
                    }

                    @media #{$media-sx} {
                        font-size: 1.8rem;
                    }
                }

                .importoAnticipo {
                    @media #{$media-s} {
                        width: 50%;
                    }
                }

                input {
                    border: 2px solid var(--c-grey-dark);
                    border-radius: 10px;
                    background-color: var(--c-white);
                    padding: $gutter / 8 $gutter / 4;
                }

                .stepRate {

                    button {
                        background-color: var(--c-white);
                        outline: none;
                        border-radius: 10px;
                        font-size: 2rem;
                        padding: 0.4rem 0.8rem;
                        border: 1px solid var(--c-grey-dark);
                        margin-right: $gutter / 4;
                        margin-bottom: $gutter / 4;

                        &:focus, &.selected {
                            background-color: var(--c-electric);
                        }
                    }
                }

                &.importoRata {
                    margin-top: $gutter / 2;

                    @media #{$media-sx} {
                        margin-top: 0;
                    }

                    .title {
                        font-size: 2.6rem;
                        font-weight: 700;

                        @media #{$media-sx} {
                            width: 100%;
                            font-size: 2rem;
                        }
                    }

                    .text {
                        background-color: var(--c-electric);
                        border-radius: 15px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: $gutter / 16 $gutter;
                    }

                    @media #{$media-sm} {
                        .title, .text {
                            flex: 1 0 auto;
                        }
                    }
                }
            }
        }

        span.btn {
            margin: 0 0 $gutter 0;
            align-self: center;
            cursor: pointer;

            svg {
                transform: rotate(180deg);
                margin-left: - $gutter / 4;
                margin-right: $gutter / 2;
            }
        }

        .bottom {
            font-size: 1.2rem;
            padding: 0 $gutter $gutter $gutter;

            @media #{$media-sx} {
                padding: 0 $gutter / 2 $gutter $gutter / 2;
            }
        }
    }
    
}

.popupGenerico {
    .white-popup {
        border: 10px solid var(--c-corporate-5);
        max-width: 550px;

        .content {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;

            h1 {
                font-size: 2.4rem;
                font-weight: 700;
            }

            p {
                margin-bottom: 0;
            }
        }

        span.btn {
            svg {
                transform: none;
                margin-left: $gutter / 4;
                margin-right: 0;
            }

            a {
                display: flex;
                width: 100%;
                height: 100%;
            }
        }

        .bottom {
            display: flex;
            justify-content: center;
            font-size: 1.2rem;
            
            span {
                cursor: pointer;
                text-decoration: underline;
                transition: $trans-default;

                &:hover {
                    color: var(--c-corporate-5);
                }
            }
        }
    }
}

#addToWishlistPopup, #removeFromWishlistPopup {
    p {
        display: none;
        padding: $gutter / 8 $gutter / 4;

        &.visible {
            display: block;
        }

        &#popupProductKo {
            background-color: var(--c-oil);
        }

        &#popupProductKo2 {
            background-color: var(--c-accent);
            color: var(--c-white);
        }

        &#popupProductOk, &#popupProductOk2 {
            background-color: var(--c-electric);
        }
    }
}