/*------------------------------------*\
    Page Template: Single cartongesso
    single-cartongesso.php
    .single-cartongesso
\*------------------------------------*/

// .single-cartongesso

.single-cartongesso{ 
    font-family: montserrat; 
    overflow-x: hidden;

    .v_blue_big{
        top: 100px;
        right: 0;
        z-index: 1;

        @media #{$media-sm} {
            display: none;
        }
    }

    .v_blue_small{
        display: none;
        top: 800px;
        right: 0;

        @media #{$media-sm} {
            display: block;
        }
    }

    .bg_house{
        top: 400px;
        left: 0;
        z-index: -1;
        width: 100%;
        height: auto;

        @media #{$media-sm} {
            top: 1000px;
            left: -200px;
        }
    }

    .left_column{
        width: 50%;
        padding-right: 40px;

        @media #{$media-sm} {
            padding-right: 0;
            width: 100%;
        }
    }

    .right_column{
        width: 50%;

        @media #{$media-sm} {
            width: 100%;
        }
    }

    .title{
        font-size: 40px;
        font-family: oswald-light;
        color: var(--blue-logo);
        padding-bottom: 40px;

        @media #{$media-sm} {
            font-size: 28px;
        }
    }

    .section1{
        width: 100%;
        margin: 0;
        display: flex;
        flex-direction: row;  

        @media #{$media-sm} {
            flex-direction: column;
        }

        .image{
            padding: 40px 0 0 60px;
            width: 100%;
            height: 600px;
            position: relative;

            @media #{$media-sm} {
                padding: 0;
                margin-top: 20px;
            }
             
            img{
                height: auto;
                position: absolute;
                height: 600px;
                object-fit: cover;
                top: 0;
                left: 0;
            } 
        }
    }

    .section2{
        width: 100%;
        padding: 40px 30% 0 0;
        margin-bottom: 80px;

        @media #{$media-sm} {
            padding-right: 0;
        }
    }

    .section3{
        width: 100%;
        margin: 0;
        padding-top: 40px;
        display: flex;
        flex-direction: row;  
        margin-bottom: 120px;

        @media #{$media-sm} {
            flex-direction: column;
        }
    }

    

    //Responsive
    @media only screen and (max-width: 990px) {       
        .section3{
            flex-direction: column;

            .right_column{
                margin-top: 60px;
            }
        }
    }

}

