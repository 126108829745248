/*------------------------------------*\
    Page Template: Contatti
    contatti.php
    .page-template-contatti
\*------------------------------------*/

// .page-template-contatti


.page-template-contatti{
    overflow-x: hidden;
    font-family: montserrat;
    
    .decoration{
        position: absolute;
        z-index: -1;
        left: -100px;
    }

    .left_column, .right_column{
        width: 50%;

        @media #{$media-m} {
            width: 100%;
        }
    }
        
    .title{
        font-family: oswald-light;
        font-size: 40px;
        color: var(--blue-logo);
        padding-top: 40px;
    }

    .section1{
        display: flex;

        @media #{$media-m} {
            flex-direction: column;
        }
        
        .left_column{
            margin-right: 20px;

            @media #{$media-m} {
                margin-right: 0px;
            }

            .contatto{
                margin-top: 20px;
                display: flex;
                align-items: center;
    
                svg{
                    margin-right: 20px;
                    min-width: 40px;
                }
            }
        }

        .right_column{

            .map_box{
                margin: 70px 20px;
                width: 100%;
                height: 500px;

                .acf-map{
                    width: 100%;
                    height: 100%;
                }

                @media #{$media-m} {
                    margin: 40px 0;
                }
            }
        }
    }

    .section2{
        background-color: var(--form-bg);
        padding: 40px;
        margin: 100px 80px;
        display: flex;
        font-size: 20px;

        @media #{$media-m} {
            flex-direction: column;
            padding: 20px;
            margin: 100px 40px;
        }

        @media #{$media-s} {
            margin: 100px 20px;
        }

        .right_column{
            padding-left: 80px;
            
            @media #{$media-m} {
                padding-left: 0px;
            }

            .title{
                margin-bottom: 60px;
                padding-top: 10px;
                
                @media #{$media-m} {
                   margin-bottom: 40px;
                }
            }
            
            .email_row{
                display: flex;
                padding-bottom: 20px;
                flex-direction: column;

                @media #{$media-s} {
                    font-size: 16px;
                }

                h3{
                    padding-right: 10px;
                    font-weight: bold;
                }
            }
        }
    }
}