/*------------------------------------*\
    Page Template: Single isolamento
    single-isolamento.php
    .single-isolamento
\*------------------------------------*/

// .single-isolamento

.single-isolamento{
    overflow-x: hidden;

    .decorazione1{
        position: absolute;
        top: 650px;
        left: -250px;
        z-index: -1;

        @media #{$media-sm} {
            top: 500px;
            left: -400px;
        }
        
    }

    .section1{
        display: flex;

        @media #{$media-sm} {
            flex-direction: column;
        }
        
        .column1{
            width: 35%;
            padding-right: 40px;

            @media #{$media-sm} {
                width: 100%;
                padding-right: 0;
            }

            img{
                width: 40%;
                height: auto;
                margin-left: 60%;
                margin-top: 60px;
                                
                @media #{$media-m} {
                    display: none;   
                }
            }
        }

        .column2{
            display: flex;
            width: 65%;
            position: relative;
            overflow-x: hidden;

            @media #{$media-sm} {
                width: 100%;
                flex-direction: column;
                position: relative;
            }

            .decorazione2{
                position: absolute;
                right: 0;

                @media #{$media-sm} {
                    top: 500px;
                    right: -250px;
                }
            }

            .left_column{
                width: 42%;
                margin: 100px 0;
                padding: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                @media #{$media-sm} {
                    width: 100%;
                    flex-direction: column-reverse;
                    margin-bottom: 0;
                }

                svg{
                    height: fit-content;
                    max-width: 380px;
                    z-index: -1;
                    
                    @media #{$media-sm} {
                        transform: rotate(180deg);
                        max-width: 100%;
                    }
                }

                picture{

                    @media #{$media-sm} {
                        width: 100%;
                    }
                }

                img{
                    width: 100%;
                    height: auto;
                    
                }
            }
    
            .right_column{
                width: 58%;
                margin: 580px 0 120px 0;
                padding-left: 40px;    

                @media #{$media-sm} {
                    width: 100%;
                    margin-top: 40px;
                    padding-left: 0;
                }

                .paragrafo{
                    color: black;
                }
            }
        }
    }

    .title{
        color: var(--blue-logo);
        font-size: 40px;
        font-family: oswald-light;
        padding-bottom: 40px;
    }

    .section2{
        width: 100%;
        margin: 0;
        padding-top: 40px;
        display: flex;
        flex-direction: row;  

        @media #{$media-sm} {
            flex-direction: column;
        }

        .left_column{
            width: 50%;
            padding-right: 40px;

            @media #{$media-sm} {
                width: 100%;
                padding: 0;
            }
        }
    
        .right_column{
            width: 50%;

            @media #{$media-sm} {
                width: 100%;
                padding-top: 40px;
            }
        }
    }
}