/*------------------------------------*\
    Page Template: Commerciali
    commerciali.php
    .page-template-commerciali
\*------------------------------------*/

// .page-template-commerciali


.page-template-commerciali{
    overflow-x: hidden;

    #container_soluzioni{
        padding: 0 40px;

        @media #{$media-sm} {
            padding: 0 20px;
        }

        .decoration{
            top: -80px;
            left: 0;
            z-index: 0;
            
            @media #{$media-s} {
                top: -10px;
            }

            svg{
                @media #{$media-s} {
                    width: 200px;
                    height: 480px;
                }
            }
        }

        .section1{
            height: fit-content;
            display: flex;
            flex-direction: row;

            @media #{$media-sm} {
                flex-direction: column;
            }

            .left_column{
                width: 33%;
                padding: 40px;
                background-color: var(--red-logo);
                min-height: 390px;

                @media #{$media-sm} {
                    width: 100%;
                    min-height: 300px;
                }

                h1{
                    color: white;
                }
    
                h2{
                    color: white;  

                    @media #{$media-sm} {
                        font-size: 35px;
                    }
                }
            }

            .right_column{
                width: 67%;
                min-height: 390px;
                background-size: cover;
                overflow: hidden;
                z-index: 1;

                @media #{$media-sm} {
                    width: 100%;
                    min-height: 300px;
                }

                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        .section2{            
            position: relative;  //serve per poter usare lo z-index
            z-index: 1;
            padding: 40px 33% 40px 40px;


            @media #{$media-sm} {
                padding: 40px 0;
            }
        }

        .section3{
            padding: 40px;            

            @media #{$media-sm} {
                padding: 20px 0;
            }

            .list{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                gap: 40px;
                
                @media #{$media-sm} {
                    justify-content: center;
                }

                @media #{$media-sx} {
                    justify-content: flex-start;
                }
            }

            .page_box{
                display: flex;
                flex-direction: row;
                align-items: center;

                .box_image{
                    display: flex;
                    align-items: center;
                    width: 210px;

                    @media #{$media-sm} {
                        width: 160px;
                    }
    
                    .cornice{
                        width: 200px;
                        height: auto;

                        svg{
                            @media #{$media-sm} {
                                height: 140px;
                                width: 140px;
                            }
                        }
                    }
    
                    img{
                        border-radius: 5000px;
                        width: 131px;
                        height: 131px;
                        min-width: 131px;
                        min-height: 131px;
                        position: relative;
                        bottom: 4px;
                        right: 155px;
                        object-fit: cover;

                        @media #{$media-sm} {
                            min-width: 100px;
                            min-height: 100px;
                            height: 100px;
                            right: 120px;
                        }
                    }
                }
    
                .box_content{
                    width: 250px;
                    
                    @media #{$media-sx} {
                        width: fit-content;
                    }

                    .title_box{
                        font-size: 30px;
                        font-family: oswald;
                        color: var(--blue-logo);
                        text-transform: uppercase;

                        @media #{$media-sx} {
                            font-size: 25px;
                        }
                    }

                    .text_box{
                        font-size: 16px;
                        font-family: montserrat;

                        @media #{$media-sx} {
                            display: none;
                        }
                    }
                }
            } 
        }
    }
}