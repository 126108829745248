/*------------------------------------*\
   Slick custom styles
\*------------------------------------*/

.slick-dots {
    display: flex;
    justify-content: center;
    gap: 10px;
    z-index: 10;
    position: relative;
    margin-top: 40px;
    margin-right: 40px;

    @media #{$media-sm} {
        margin-right: 20px;
    }

    li {
        button {
            color: transparent;
            border-radius: 50%;
            background-color: none;
            border: 1px solid var(--c-corporate-5);
            outline: none;
            width: 20px;
            height: 20px;
            color: none;
        }

        &.slick-active {
            button {
                background-color: var(--c-corporate-5);
            }
        }
    }
}