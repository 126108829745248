/*------------------------------------*\
   Paginazione ( custom_pagination() --> extra  )
   .pagination
\*------------------------------------*/

.pagination {

	margin: $gutter * 2 0 $gutter * 4 0; 
	display: inline-flex;
	gap: 2rem;
	flex-wrap: wrap;
	justify-content: center;

	@media #{$media-sx} {
		margin: $gutter / 2 0; 
	}

	.next, .prev {
		display: none;
	}

	span,
    a {

		display: flex;
		justify-content: center;
		align-items: center;
		width: 4rem;
		height: 4rem;
		margin: 0;
		padding: 0;
		border: 2px solid var(--c-corporate-1);
		background-color: var(--c-white);
		color: var(--c-black);
		font-size: 2.6rem;
		font-weight: 500;
		font-family: $f-family-menu;
		transition: $trans-default;

		@media #{$media-sx} {
			font-size: 2.2rem;
		}

		&:hover:not(.current) {
			color: var(--c-white);
			background-color: var(--c-corporate-5);
		}

    }

	.current {
		color: var(--c-white);
		background-color: var(--c-corporate-5);

	}// .current

	.first { margin: 0; }

	.first,
	.prev,
	.next,
	.last {}

}// .pagination
