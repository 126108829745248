/*------------------------------------*\
    Page Template: Notizie
    notizie.php
    .page-template-notizie
\*------------------------------------*/

// .page-template-notizie

.page-template-notizie{
    display: block;

    .container_news{
        width: 100%;
        font-family: montserrat;
        padding-top: 80px;
        display: flex;
        flex-wrap: wrap;

        .box_news{
            width: 30%;
            padding: 20px;
            .box_news_date{
                padding-bottom: 20px;
            }

            .box_news_text{
                padding: 20px 0;
            }
                        
            a{
                text-transform: uppercase;
                text-decoration: underline;
                color: var(--blue-logo);
                padding-top: 20px;   
            }
        }

        
        .line{
            border-bottom: 1px solid black;
            margin: 20px 0;
        }
    }
}