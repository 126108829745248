/*------------------------------------*\
    Page Template: Chi siamo
    chi_siamo.php
    .page-template-chi_siamo
\*------------------------------------*/

// .page-template-chi_siamo


.page-template-chi_siamo{
    overflow-x: hidden;
    
    .blue_box{
        margin: 0 40px;
        padding: 40px 40px 80px 40px;
        display: flex;
        background-color: var(--blue-dark);
        color: white;

        h2{
            color: white;
        }

        .left_column{
            width: 40%;
            padding-right: 30px;
        }

        .center_column{
            width: 20%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 40px;

            img{
                width: 100%;
                height: auto;
            }
        }

        .right_column{
            width: 40%;
            padding-left: 30px;
            margin-top: 400px;
        }
    }

    .main_box{
        .section1{
            padding: 60px 20%;
            text-align: center;
        }

        .section2{
            display: flex;
            justify-content: center;
        }

        //timeline

        /*
        .timeline{
            display: flex;
            justify-content: center;
            margin: 60px 0 120px 0;
            position: relative;
            width: fit-content;

            .timeline_line{
                width: 100%;
                height: 2px;
                background-color: var(--blue-logo);
                position: absolute;
                top: 15px;
            }

            .timeline_box{
                display: flex;
                flex-direction: column;
                align-items: center;
                font-family: oswald;
                color: var(--blue-logo);

                .timeline_dot{
                    z-index: 2;
                }

                .timeline_box_line1, .timeline_box_line2{
                    width: 2px;
                    background-color: var(--blue-logo);
                    margin: 15px 0 20px 0;
                }

                .timeline_box_line1{
                    height: 120px;
                }

                .timeline_box_line2{
                    height: 60px;
                }

                .year{
                    text-align: center;
                    font-size: 30px;
                    line-height: 100%;
                }

                .event{
                    font-size: 20px;
                    text-align: center;
                    line-height: 120%;
                }
            }
        }

        .vertical_timeline{
            display: none;
        }
        */
    }


    @media only screen and (max-width: 1000px) {
        .blue_box{
            flex-direction: column;
            margin: 0 20px;
            padding: 40px 20px;        

            .paragrafo_page{
                text-align: center;
            }

            .left_column{
                width: 100%;
                padding-right: 0;
            }
    
            .center_column{
                width: 100%;
                padding-top: 40px;
    
                .image{
                    width: 100%;
                    height: auto;
                }
            }
    
            .right_column{
                margin-top: 40px;
                width: 100%;
                padding: 0;
            }
        }

        .main_box{
            .section1{
                padding: 60px 40px;
            }

            .section2{                

                .timeline{
                    flex-direction: column;    
                    margin: 0 40px;
                    
                    .timeline_line{
                        width: 2px;
                        height: 100%;
                        top: 0px;
                        left: 15px;
                    }
        
                    .timeline_box{
                        flex-direction: row;
                        margin: 10px 0;

                        .timeline_box_line1, .timeline_box_line2{
                            display: none;
                        }

                        .year{
                            text-align: left;
                            margin-left: 40px;
                        }
        
                        .event{
                            text-align: left;
                            margin-left: 20px;
                        }
                    }

                    
                }
            }

            
        }
    }
}