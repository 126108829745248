/*------------------------------------*\
    Page 404: 404
    404.php
    .error404
\*------------------------------------*/

.error404{
    .box--404 {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin-top: $gutter * 4;
        padding: $gutter * 2 $gutter * 3;
        position: relative;

        @media #{$media-m} {
            padding: $gutter * 2 $gutter * 2;
            margin-top: $gutter * 3;
        }

        @media #{$media-sm} {
            padding: $gutter;

        }

        @media #{$media-sx} {
            padding: $gutter $gutter / 2;
            margin-top: $gutter * 2.5;
        }

        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 781px;
            z-index: -1;
            background: url('../images/generico.svg') no-repeat top right / contain;
        }

        h1.title {
            font-size: 2.4rem;
            font-weight: 500;
            color: var(--c-corporate-4);
            margin-bottom: $gutter / 2;

            @media #{$media-s} {
                font-size: 2rem;
            }
        }

        h2.title2 {
            font-size: 4.6rem;
            font-weight: 700;
            line-height: 1.4;
            color: var(--c-corporate-4);
            margin-bottom: $gutter;

            @media #{$media-sm} {
                font-size: 3.2rem;
                margin-bottom: $gutter / 4;
            }
        }

        .text {
            @media #{$media-sx} {
                font-size: 1.4rem;
            }
        }

    }//.box--404 
}
//.error404