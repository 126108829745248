/*------------------------------------*\
    Page Index: Blog
    index.php
    .archive
\*------------------------------------*/

.archive,
.single-post {
    overflow-x: hidden;    

    h1{
        display: none;
    }

    .post--archive {
        display: flex;
        flex-wrap: wrap;
        position: relative;


        //.col-1

        .blog--list {
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: 0 $gutter * 1.5 $gutter * 3 $gutter * 1.5;

            @media #{$media-sm} {
                padding: 0 $gutter $gutter * 3 $gutter;
            }

            @media #{$media-sx} {
                padding: 0 $gutter / 2 $gutter $gutter / 2;
            }

            .box_news {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                gap: 40px;

                @media #{$media-bx} {
                    gap: 25px;
                }

                @media #{$media-b} {
                    gap: 40px;
                }

                @media #{$media-sm} {
                    gap: 0;
                }

                .single_news {
                    width: 30%;
                    padding-bottom: $gutter;
                    border-bottom: 1px solid var(--c-corporate-1);
                    margin-bottom: $gutter;

                    @media #{$media-b} {
                        width: 46%;
                    }

                    @media #{$media-sm} {
                        width: 100%;
                        max-width: none;
                    }

                    .top {
                        display: flex;
                        height: $gutter * 1.2;
                        position: relative;
                        font-size: 2rem;
                        font-family: $f-family-menu;

                        @media #{$media-sx} {
                            font-size: 1.8rem;
                            height: auto;
                            margin-bottom: $gutter / 4;
                        }
                    }
                    
                    .image{
                        width: 100%;
                        height: 200px;

                        @media #{$media-sm} {
                            width: auto;
                            height: auto;
                        }  

                        img{
                            width: 100%;
                            max-height: 200px;
                            filter: brightness(80%);
                            object-fit: cover;
                            border: 3px solid #707070;
                        }
                    }
                    
                    .title .paragrafo{
                        margin: 20px 0;

                        @media #{$media-m} {
                            text-align: left;
                        }       
                    }
                }
            }
        }
    }
    
    /* !! POST SINGLE !! */

    .post--single {
        
        .blog--detail {
            margin-top: 40px;
            padding: 0 25%;

            @media #{$media-sm} {
                padding: 0 20px;
            }
            
            .time {
                margin-bottom: 20px;
                font-family: montserrat;
            }

            h3{
                font-size: 40px;
                font-family: oswald-light;
                color: var(--blue-logo);
                margin-bottom: 20px;

                @media #{$media-sm} {
                    font-size: 27px;
                }
            }

            picture{
                width: 100%;
                height: 200px;
                margin-bottom: 20px;

                img{
                    width: 100%;
                    max-height: 200px;
                    filter: brightness(80%);
                    object-fit: cover;
                    border: 3px solid #707070;   
                }
            }
            
            .custom_link::after{
                content: none;
            }

            .custom_link::before{
                content: "";
                background-image: url('../images/icon_link_arrow.svg');
                width: 20px; 
                height: 20px;
                display: inline-block;
                background-size: cover;
                background-position: center;
                margin-right: 10px; 
                transform: rotate(180deg);
            }

            .link {
                display: flex;
                justify-content: center;
                
                svg {
                    transform: rotate(180deg);
                }
            }

            .time {
                color: var(--c-corporate-2);
                display: inline-block;
                margin-bottom: $gutter / 3;
            }
        }
    }

    //.post--archive

    .link {
        

        a {
            display: inline-flex;
            align-items: center;
            text-decoration: underline;

            .text {
                font-size: 1.8rem;
                font-weight: 500;
                font-family: $f-family-menu;
                text-transform: uppercase;
                transition: 0.2s;

                @media #{$media-sx} {
                    font-size: 1.6rem;
                }
            }
            /*
            &:hover {
                color: var(--c-accent);

                .text {
                    transform: translateX(10px);
                }
            }

            &:hover::after {
                transform: translateX(10px);
                transition: 0.2s;
            }*/
        }
    }
}

//.archive