// @function hsl($h, $s, $l) {
//     @return unquote('hsl(#{$h}, #{$s}, #{$l})');
// }
@function hsla($var, $a) {
    @return unquote('hsla(#{$var}, #{$a})');
}




// Responsive Media Query ( html-font-size = 62.5% = 1rem = 10px )
$media-uw:              "(min-width: 1900px)";      // media query > 1900     // container 1900
$media-u:               "(max-width: 1900px)";      // media query < 1900     // container 1900
$media-bx:              "(max-width: 1600px)";      // media query < 1600     // container 1170
$media-b:               "(max-width: 1280px)";      // media query < 1280     // container 97
$media-m:               "(max-width: 1080px)";      // media query < 1080     // container 74
$media-sm:              "(max-width: 820px)";       // media query < 820      // container 60
$media-s:               "(max-width: 640px)";       // media query < 640      // container 46
$media-sx:              "(max-width: 480px)";       // media query < 480      // container 100%
$media-sxx:             "(max-width: 360px)";       // media query < 360      // container 100%

$media-uw:              "(min-width: 1921px)";      // media query > 1920

$media-bx-min:          "(min-width: 1601px)";      // media query > 1600
$media-b-min:           "(min-width: 1281px)";      // media query > 1280
$media-m-min:           "(min-width: 1081px)";      // media query > 1040
$media-sm-min:          "(min-width: 821px)";      // media query > 820
$media-s-min:           "(min-width: 641px)";       // media query > 640

$media-motion:          "screen and (prefers-reduced-motion: no-preference)";



// Width
$w-media-uw:            190rem;
$w-media-u:             157rem;
$w-media-bx:            117rem;
$w-media-b:             97rem;
$w-media-m:             74rem;
$w-media-sm:            60rem;
$w-media-s:             46rem;
$w-media-sx:            100%;

// Height
$h-header:              16rem;

// Gutter
$gutter:                4rem;





// Colors
// Color Names -> http://chir.ag/projects/name-that-color/
/* Used colors

#1B365C     -> Corporate1       -> hsl(215, 55%, 23%)
#0479BB     -> Corporate2       -> hsl(202, 96%, 37%)
#7EA1C4     -> Corporate3       -> hsl(210, 37%, 63%)
#455B5F     -> Corporate4       -> hsl(210, 37%, 63%)

#C3C320     -> Accent           -> hsl(60, 72%, 45%)

#061BC7     -> Theme Color 2    -> hsl(233, 94%, 40%)
#0651D1     -> Theme Color 3    -> hsl(218, 94%, 42%)
#0479BB     -> Theme Color 4    -> hsl(202, 96%, 37%)
#06BCD1     -> Theme Color 5    -> hsl(186, 94%, 42%)
#8090c7     -> Theme Color 5    -> hsl(226, 39%, 64%)

#f6a500     -> Oil & Gas        -> hsl(40, 100%, 48%)
#5bc4f1     -> Hydraulic        -> hsl(198, 84%, 65%)
#91c36e     -> Electric         -> hsl(95, 41%, 60%)

#8a8a89     -> Carbon Steel     -> hsl(60, 0%, 54%)
#497181     -> Stainless Steel  -> hsl(197, 28%, 40%)


// greyscale

#000000     -> hsl(0, 0%, 0%)

#707070     -> hsl(0, 0%, 44%)
#cccccc     -> hsl(0, 0%, 80%)
#ebebeb     -> hsl(0, 0%, 92%)

#ffffff     -> hsl(0, 0%, 100%)

*/

:root {
    // colors
    --c-corporate-1:        black;
    --c-corporate-5:        var(--blue-logo);
    --c-accent:             #0046AA;
    --c-white:              white;

    --blue-logo:            #0046AA;
    --red-logo:             #E73B3C;
    --blue-dark:            #00255A;
    --form-bg:              #EAF2FD;
    --slider-bg:            #F4F4F4;

    --c-bg:                 var(--c-grey-light);
    --c-fg:                 var(--c-corporate-1);

    --c-link:               var(--c-accent);
    --c-link-hover:         var(--c-corporate-1);

    --c-error:              #c00;
    --c-modal:              var(--c-black);

    /*
    // height
    --h-header:             16rem;

    // gutter
    --gutter:               4rem;

    // font
    --f-title:              "Century Gothic", sans-serif;
    --f-text:               "Century Gothic", sans-serif;

    // --f-300:                300;
    // --f-400:                400;
    // --f-500:                500;
    // --f-600:                600;
    // --f-700:                700;
    */
}

// Fonts
// centurygothic:wght@400,700 -> loaded via local Font
// lato:wght@300,400,700,900 -> loaded via local Font

$f-family-title:        "Century Gothic", sans-serif;
$f-family-text:         "Century Gothic", sans-serif;
$f-family-menu:        "Lato", sans-serif;

$f-weight-300:          300;
$f-weight-400:          400;
$f-weight-700:          700;
$f-weight-900:          900;

// Transitions
$trans-default:         0.15s all ease;
$trans-easinout:        0.25s all ease-in-out;
