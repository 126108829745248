/*------------------------------------*\
    Page Template: Realizzazioni
    realizzazioni.php
    .page-template-realizzazioni
\*------------------------------------*/

// .page-template-realizzazioni

.page-template-realizzazioni{
    overflow-x: hidden;

    .section1{
        padding: 40px 50% 140px 80px;
        width: 100%;   
        position: relative;     

        @media #{$media-m} {
            padding-left: 40px;
            padding-right: 40px;
        }

        @media #{$media-sx} {
            padding-left: 20px;
            padding-right: 20px;
        }

        .decorazione{
            position: absolute;
            bottom: -10px;
            right: -450px;
            z-index: 1;
    
            @media #{$media-m} {
                display: none;
            }
        }
    }

    .section2{
        background-color: #F4F4F4;
        padding: 60px 80px 120px 80px;
        position: relative;

        @media #{$media-m} {
            padding-left: 40px;
            padding-right: 40px;
        }

        @media #{$media-sx} {
            padding-left: 20px;
            padding-right: 20px;
        }
        
        .decorazione{
            position: absolute;
            top: 0px;
            right: -376px;
            z-index: 1;
    
            @media #{$media-m} {
                display: none;
            }
        }

        .title{
            font-size: 44px;
            font-family: oswald;
            padding-bottom: 20px;
            text-transform: uppercase;
        }

        .paragrafo{
            text-align: left;
        }

        .box_list{
            display: flex;
            align-items: center;
            justify-content: center;

            .list{
                margin-top: 80px;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;

                @media #{$media-sm} {
                justify-content: center;
                }

                .box_cantiere{
                    width: 330px;
                    height: 330px;
                    margin: 20px;
                    background-color: black;
                    position: relative;

                    picture{
                        width: 100%;
                        height: 100%;
                        overflow: hidden;

                        img{
                            width: 100%;
                            height: auto;
                            filter: brightness(50%);
                            object-fit: cover;
                            transition: 0.1s linear;
                        }
    
                        img:hover{
                            -webkit-transition: 0.1s linear;
                            filter: brightness(30%);
                            -webkit-transform: scale(1.15);
                            transform: scale(1.15);
                        }
                    }
                    
                    .text_box1, .text_box2{
                        position: absolute;
                        left: 20px;
                        color: #F4F4F4;
                        font-family: oswald;
                        z-index: 2;
                    }

                    .text_box1{
                        bottom: 40px;
                    }

                    .text_box2{
                        bottom: 20px;
                    }
                }

                #box_cantiere_fake_1, #box_cantiere_fake_2, #box_cantiere_fake_3 {
                    background-color: #F4F4F4;
                    height: 1px!important;
                    
                    @media #{$media-sm} {
                        display: none;
                    }
                }

                #box_cantiere_fake_2, #box_cantiere_fake_3 {
                    @media #{$media-bx} {
                        display: none;
                    }
                }
            }
        }
        
        .pagination{
            display: flex;
        }
    }

    
    //Responsive
    @media only screen and (max-width: 990px) {       
        .section1{
            width: 100%;
        }       
    }
}


