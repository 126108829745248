/*------------------------------------*\
    Page Template: Preventivi
    preventivi.php
    .page-template-preventivi
\*------------------------------------*/

// .page-template-preventivi


.page-template-preventivi{
    overflow-x: hidden;    

    .v_grey{
        z-index: -999;
        left: -100px;
    }


    .section1{
        display: flex;

        .left_column{
            width: 50%;

            h3{
                color: var(--blue-logo);
                font-family: oswald-light;
                font-size: 40px;
                margin-bottom: 40px;
            }

            .form_box{
                background-color: var(--form-bg); 
                padding: 20px;
            }
        }

        .right_column{
            width: 50%;
            padding-left: 40px;
        }
    }

    //Responsive
    @media only screen and (max-width: 990px) { 

        .v_blue{
            top: 1500px
        }

        .section1{
            flex-direction: column-reverse;

            .left_column{
                width: 100%;
            }

            .right_column{
                width: 100%;
                padding: 0;
            }
        }
    }
}